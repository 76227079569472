<template>
	<v-container grid-list-lg fluid class="video-gallery">
		<v-layout row wrap>
			<div v-for="item in items" :key="item.v_id" class="video-card">
				<Video :item="item" :re="re"/>
			</div>
		</v-layout>
	</v-container>
</template>
<script>
import Video from "./items/video.vue";

export default {
	props: ["items", "channel_title", "strip_season"],
	components: { Video },
	data() {
		return {
			re: null
		};
	},
	created() {
		if (this.channel_title)
			this.re = new RegExp(
				"^.*? - \\s*" +
					(this.strip_season ? "(\\d+\\s+сезон\\s*,?|)" : ""),
				"i"
			);
	}
};
</script>
<style scoped>
.video-card {
	padding: 8px;
}
@media only screen and (max-width: 500px) {
	.video-card {
		padding-left: 0px;
		padding-right: 0px;
	}
}
</style>
