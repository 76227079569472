<template>
	<v-container>
		<material-card id="main-card" color="info" :title="title + ' - ' + total">
			<template v-slot:headerRight>
				<v-btn text @click="toggleHideView">{{ hideViewed ? 'Показать просмотренное' : 'Скрыть просмотренное' }}</v-btn>
				<v-btn text @click="reSort">{{ sort != 'last_viewed' ? 'По обновлениям' : 'По просмотрам' }}</v-btn>
			</template>
			<Progress :error="error" :loading="loading"></Progress>
			<v-alert v-if="!loading && !filteredItems.length" border="left" type="success">
				Как только вы посмотрите хотя бы одну серию любого сериала, тут будет:
				<br />— Последняя просмотренная серия — это удобно, не нужно больше запоминать!
				<br />— Количество новых серий.
			</v-alert>
			<v-container class="channel-gallery" v-if="filteredItems.length">
				<v-row wrap justify-left>
					<div class="item" v-for="item in filteredItems" :key="item.id">
						<router-link
							:to="item.next ? { name: 'video', params: { id: item.next.v_id }} : { name: 'channelPage', params: { address: item.address }}"
						>
							<div style="position: relative;">
								<v-img
									:src="getPhotoPath('channel', item.id) + item.photo + '_150.jpg'"
									:srcset="getPhotoPath('channel', item.id) + item.photo + '.jpg 2x'"
									:alt="item.title_rus"
									width="150"
									height="222"
									eager
								></v-img>
								<v-tooltip top v-if="item.section != 'movie'">
									<template v-slot:activator="{ on }">
										<v-progress-linear
											:value="getOverallProgress(item)"
											v-on="on"
											color="#66bb6a"
											class="view"
										></v-progress-linear>
									</template>
									<span>Прогресс просмотра сериала</span>
								</v-tooltip>
								<v-btn
									fab
									x-small
									dark
									color="#66bb6a"
									right
									bottom
									absolute
									depressed
									v-if="item.new_video_count"
									@click.prevent="videoAfter(item.id, item.last_video_id)"
								>{{ item.new_video_count }}</v-btn>
							</div>
							<div class="text-center" style="max-width: 150px; height: 76px;">
								<div class="channel-title" v-html="item.title_rus"></div>
							</div>
							<v-row class="button">
								<div
									class="button-title"
									:class="item.next ? '' : 'title-gray'"
								>{{ item.next ? item.next.v_title : (item.v_title ? item.v_title : 'Не просмотрено') }}</div>
								<template v-if="item.next">
									<div
										:style="'width:' + getPosition(item.next) + '%'"
										class="button-progress"
									/>
									<div
										class="button-left"
										:class="getPosition(item.next) == 0 ? 'button-full' : ''"
									/>
								</template>
								<template v-else>
									<div class="button-last" />
								</template>
							</v-row>
						</router-link>
					</div>
					<v-flex xs12 class="text-md-center">
						<scroll-pagination v-if="hasMorePage" :page="page" :loading="loading" @page="onScroll"></scroll-pagination>
					</v-flex>
				</v-row>
			</v-container>
		</material-card>

		<v-dialog v-model="videoDialog">
			<Progress :loading="videoDialogLoading"></Progress>
			<v-expansion-panels v-model="videoPanel" multiple v-if="categories">
				<v-expansion-panel v-for="(category, i) in categories" :key="i">
					<v-expansion-panel-header v-html="category.name" />
					<v-expansion-panel-content>
						<video-list :items="category.list" channel_title="true" strip_season="true"></video-list>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
			<v-btn fixed fab top right style="z-index: 300;" small @click.stop="videoDialog = false">
				<v-icon>icon-close</v-icon>
			</v-btn>
		</v-dialog>
	</v-container>
</template>

<script>
import { Helper } from "../mixins/Helper";
import ScrollPagination from "../components/helper/scroll-pagination.vue";
import VideoList from "../components/video-list.vue";
import { Touch } from "vuetify/lib/directives";

export default {
	mixins: [Helper],
	components: { ScrollPagination, VideoList },
	directives: { Touch },
	data() {
		return {
			loading: true,
			items: [],
			filteredItems: [],
			error: null,
			hasMorePage: false,
			page: 1,
			total: 0,
			videoDialog: false,
			videoDialogLoading: true,
			videoPanel: [0],
			categories: null,
			title: "Я смотрю",
			section: "",
			sort: "last_viewed",
			hideViewed: false
		};
	},
	created() {
		this.hideViewed = localStorage.getItem("hide_viewed") == 'true';
		this.section = this.$route.params.section;
		this.fetchData();
	},
	methods: {
		fetchData() {
			let t = this,
				sort0 = localStorage.getItem("look_sort");
			t.error = null;
			t.loading = true;
			t.sort = sort0 ? sort0 : "last_viewed";
			if (t.sort == "last_video") t.$store.commit("ws/setCount", { ncu: 0 });

			if (t.section == "series") t.title = "Мои сериалы";
			else if (t.section == "anime") t.title = "Моё аниме";
			if (t.section == "movie") t.title = "Посмотреть позже";
			document.title = t.title;

			window.ajax(
				"/api/look/" + t.section,
				{ body: { page: t.page, sort: t.sort } },
				function(data, error) {
					t.loading = false;
					if (!error) {
						t.hasMorePage = data.hasMore;
						if (t.page == 1) {
							t.items = data.list;
							t.total = data.total;
						} else {
							t.items = [...t.items, ...data.list];
						}
						t.filter();
					} else {
						t.error = data;
					}
				}
			);
		},
		filter() {
			let t = this;
			if (!t.hideViewed) t.filteredItems = t.items;
			else {
				t.filteredItems = t.items.filter((item) => item.new_video_count != 0 || item.last_video_id == 0);
			}
		},
		onScroll(page) {
			this.page = page;
			this.fetchData();
		},
		unsubscribe(item) {
			let yes = true;
			if (!item.del) yes = confirm("Вы уверены, что хотите отписаться?");
			if (!yes) return;

			window.ajax("/api/subscribe/set/" + item.id, [], function(data, error) {
				if (!error) {
					item.del = !data.status;
				}
			});
		},
		videoAfter(channel_id, last_video_id) {
			let t = this;
			t.categories = null;
			t.videoDialogLoading = true;
			t.videoDialog = true;
			t.videoPanel = [0];
			window.ajax(
				"/api/video-after",
				{ body: { id: channel_id, last_video: last_video_id } },
				function(data, error) {
					if (!error) {
						t.categories = data.categories;
					}
					t.videoDialogLoading = false;
				}
			);
		},
		getPosition(video) {
			if (video.position) return video.position / video.duration * 100;
			else {
				let pos = localStorage.getItem("pos" + video.v_id);
				if (pos) return pos / video.duration * 100;
			}
			return 0;
		},
		getOverallProgress(item) {
			if (!item.new_video_count) return 100;
			return 100 - (100 * item.new_video_count) / item.count_video;
		},
		reSort() {
			let new_sort = this.sort == "last_viewed" ? "last_video" : "last_viewed";
			localStorage.setItem("look_sort", new_sort);
			this.$router.go();
		},
		toggleHideView() {
			let t = this;
			t.hideViewed= !t.hideViewed;
			localStorage.setItem("hide_viewed", t.hideViewed);
			t.filter();
		}
	},
	beforeRouteUpdate(to, from, next) {
		this.section = to.params.section;
		this.fetchData();
		next();
	}
};
</script>
<style lang="scss" scoped>
.container {
	max-width: 1100px;
}
.menu {
	display: none;
}
.v-list-item:hover .menu {
	display: block;
}
.channel-gallery > div > div {
	padding: 12px;
	padding-bottom: 24px;
}
.channel-title {
	color: black;
	padding-top: 8px;
}
.theme--dark .channel-title {
	color: white;
}
.channel-gallery ::v-deep {
	.v-btn--absolute.v-btn--right,
	.v-btn--fixed.v-btn--right {
		right: -18px;
	}
	button {
		font-size: 14px;
	}
}

.button {
	width: 100%;
	height: 35px;
	text-align: center;
	position: relative;
	margin: 0;
	border-radius: 4px;
}
.item:hover .button {
	background-color: black;
	opacity: 0.8;
}
.button-title {
	position: absolute;
	top: 9px;
	width: 100%;
	color: white;
	font-size: 13px;
	overflow: hidden;
	height: 28px;
	line-height: normal;
}
.button-progress {
	width: 50%;
	background-color: #4f9853;
	height: 100%;
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px;
}
.button-left {
	background-color: #66bb6a;
	flex-grow: 1;
	height: 100%;
	border-bottom-right-radius: 4px;
	border-top-right-radius: 4px;
}
.button-full {
	border-radius: 4px;
}
.button-last {
	background-color: #d5d5d5;
	flex-grow: 1;
	height: 100%;
	border-radius: 4px;
}
.title-gray {
	color: #626368;
}
@media only screen and (max-width: 450px) {
	.channel-gallery > div > div {
		padding: 0;
		padding-bottom: 12px;
		padding-left: 5px;
	}
}
@media only screen and (max-width: 600px) {
	.v-list-item__subtitle .new {
		display: block;
	}
}
</style>